<template>
  <ui-component-modal
    modalTitle="Edit basic data"
    :onClickCancel="onClickCancel"
    :onClickSave="saveCartData"
    :showModal="showModal"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :savingSuccessMessage="'Data saved'"
    :savingErrorMessage="'Something went wrong'"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <div v-if="showCartData">
        <table class="table is-fullwidth is-striped">
          <tbody>
            <tr>
              <td>{{ $t('Components.Carts.EditCartData.Table_Name') }}</td>
              <td>
                <input type="text" class="input" v-model="mCart.Name" />
              </td>
            </tr>
            <tr>
              <td>{{ $t('Components.Carts.EditCartData.Table_Company') }}</td>
              <td>
                <span>{{ mCart.CompanyName }}</span>
                <span>
                  <a
                    @click="setShowSearchCompany"
                    class="button is-small is-success"
                    >{{ $t('Components.Carts.EditCartData.Button_Change') }}</a
                  >
                </span>
              </td>
            </tr>
            <tr>
              <td>{{ $t('Components.Carts.EditCartData.Table_Profile') }}</td>
              <td>
                <span class="has-margin-right">{{ mCart.ProfileName }}</span>
                <span>
                  <a
                    @click="setShowSearchProfile"
                    class="button is-small is-success"
                    >{{ $t('Components.Carts.EditCartData.Button_Change') }}</a
                  >
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="showSearchCompany">
        <a @click="setShowCartData">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>{{ $t('Components.Carts.EditCartData.Button_Back') }}</span>
        </a>

        <p class="subtitle">
          {{ $t('Components.Carts.EditCartData.Subtitle_Search-company') }}
        </p>

        <search-company
          :locationId="mCart.LocationId"
          @companySelected="setCompany"
        ></search-company>
      </div>

      <div v-if="showSearchProfile">
        <a @click="setShowCartData">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>{{ $t('Components.Carts.EditCartData.Button_Back') }}</span>
        </a>

        <p class="subtitle">
          {{ $t('Components.Carts.EditCartData.Subtitle_Search-profile') }}
        </p>

        <search-profile
          :locationId="mCart.LocationId"
          @profileSelected="setProfile"
        ></search-profile>

        <div>
          <a @click="setShowCreateProfile">{{
            $t('Components.Carts.EditCartData.Button_Create-profile')
          }}</a>
        </div>
      </div>

      <div v-if="showCreateCompany">
        <a @click="setShowCartData">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>{{ $t('Components.Carts.EditCartData.Button_Back') }}</span>
        </a>

        <p class="subtitle">
          {{ $t('Components.Carts.EditCartData.Subtitle_Create-company') }}
        </p>
      </div>

      <div v-if="showCreateProfile">
        <a @click="setShowCartData">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          <span>Back</span>
        </a>

        <p class="subtitle">
          {{ $t('Components.Carts.EditCartData.Subtitle_Create-profile') }}
        </p>

        <create-profile></create-profile>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import cartProvider from '@/providers/cart'
import companyProvider from '@/providers/company'

const CreateProfile = () => import('@/components/Profiles/CreateProfile')
const SearchCompany = () => import('@/components/Companies/SearchCompany')
const SearchProfile = () => import('@/components/Profiles/SearchProfile')

export default {
  components: {
    'create-profile': CreateProfile,
    'search-company': SearchCompany,
    'search-profile': SearchProfile
  },

  props: {
    showModal: {
      type: Boolean,
      default: false
    },

    onClickCancel: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      isSavingError: false,
      isSavingSuccess: false,
      isSaving: false,
      isSearchingCompany: false,
      mCart: null,
      showCartData: true,
      showCreateCompany: false,
      showCreateProfile: false,
      showSearchCompany: false,
      showSearchProfile: false
    }
  },

  computed: {
    ...mapState('cartStore', ['cart'])
  },

  created() {
    this.mCart = JSON.parse(JSON.stringify(this.cart))
  },

  methods: {
    ...mapMutations('cartStore', ['setCart']),

    setCompany(company) {
      this.mCart.CompanyId = company.Id
      this.mCart.CompanyName = company.Name

      if (this.mCart.Name === '') {
        this.mCart.Name = company.Name
      }

      this.setShowCartData()
    },

    setProfile(profile) {
      this.mCart.ProfileId = profile.Id
      this.mCart.ProfileName = profile.FullName

      if (this.mCart.Name === '') {
        this.mCart.Name = profile.FullName
      }

      this.setShowCartData()
    },

    setShowCartData() {
      this.setVisibleItem('cart')
    },

    setShowCreateCompany() {
      this.setVisibleItem('createCompany')
    },

    setShowCreateProfile() {
      this.setVisibleItem('createProfile')
    },

    setShowSearchCompany() {
      this.setVisibleItem('searchCompany')
    },

    setShowSearchProfile() {
      this.setVisibleItem('searchProfile')
    },

    setVisibleItem(item) {
      let self = this

      self.showCartData = item === 'cart'
      self.showCreateCompany = item === 'createCompany'
      self.showCreateProfile = item === 'createProfile'
      self.showSearchCompany = item === 'searchCompany'
      self.showSearchProfile = item === 'searchProfile'
    },

    saveCartData() {
      let self = this
      self.isSaving = true

      cartProvider.methods
        .updateCart(self.mCart)
        .then(response => {
          if (response.status === 200) {
            self.setCart(response.data)

            self.isSavingSuccess = true

            setTimeout(() => {
              self.onClickCancel()
            }, 1500)
          }
        })
        .catch(error => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    }
  }
}
</script>
